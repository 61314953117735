/* Logo colors:
Mostaza = #b5975a
Rojo = #811f21 */
.app{
  background-image:url("assets/img/wave.svg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: auto;
  margin-bottom: -2px;
  padding-bottom: 0.25rem;
  text-decoration: none;

}
.address{
  background-image:url("assets/img/address.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 200%;
  text-align: right;
  color: white;
  padding-right: 5%;
  padding-top: 5%;
  padding-bottom: 5%;
  text-decoration: none;
}
.address a{
  text-decoration: none;
}
.logo{
  min-width: 40px;
  max-width: 100px;
}
#title{
  color: #811f21;
  display: inline-block;
  text-align: center;
  margin-left: -10px;
}
.card-title{
  color: #811f21;
  text-shadow: 1px 1px 1px black;
}
.card-text{
  line-height: 1rem;
}
.card{
  outline-color: #b5975a;
  outline-style: outset;
  outline-width: 1px;
}
.links{
  outline-color: #811f21;
  outline-style: outset;
  outline-width: 1px;
  padding: 5px;
  color: #811f21;
  text-decoration: none;
}
.links a{
  text-decoration: none;
}
.links:hover{
  color: #b5975a;
  background-color: #811f21;
  cursor: pointer;
}
.gps{
  outline-color: #811f21;
  outline-style: outset;
  outline-width: 1px;
  color: white;
  margin: 0 auto;
  background-color: #811f21;
  padding: 5px;
  text-align: right;
  position: relative;
  width: fit-content;
  text-decoration: none;
}
.gps:hover{
  color: #b5975a;
  background-color: #811f21;
  cursor: pointer;
  text-decoration: none;
}
.gps a:hover{
  text-decoration: none;
}
footer{
  color: white;
  background-color: rgba(0,0,0, 0.7);
  position: relative;
  margin-bottom: 0;
  width: 100%;
  padding-bottom: 15px;
  padding-top: 15px;
}
footer a{
  color: #fff;
  text-decoration: none;
  margin-bottom: 20px;
}
footer a:hover{
  color: #b5975a;
}
.iconos{
  color:#b5975a;
  width: 25%;
  display: flex;
  margin: auto;
}

a, a:link, a:hover{
  text-decoration: none!important;
  text-decoration-line: none!important;
}
.servicios{
  background-color: rgba(0,0,0, 0.5);
  color: white;
}
.nav-link{
  margin-top: 2px;
  margin-bottom: 2px;
  margin-right: 5px;
  margin-left: 5px;
  padding: 4px 0px!important;
}
.navbar-collapse{
  margin-left: 10px;
}
.product-img{
  max-width: 100%;
  margin: auto;
}
.category-title{
  border-width: thick;
  border-top-color: #811f21;
  border-bottom-color: #811f21;
  border-style: solid;
  color: #b5975a;
  padding-top: 5px;
  padding-bottom: 5px;
  text-shadow: 1px 1px 3px black;
}
.header-acero{
  background-image: url("assets/img/banners/acero.jpg");
  color: #b5975a;
}
.header-conexiones{
  background-image: url("assets/img/banners/conexiones.jpg");
  color: #b5975a;
}
.header-electricidad{
  background-image: url("assets/img/banners/electricidad.jpg");
  color: #b5975a;
}
.header-equipos{
  background-image: url("assets/img/banners/equipos.jpg");
  color: #b5975a;
}
.header-ferreteria{
  background-image: url("assets/img/banners/ferreteria.jpg");
  color: #b5975a;
}
.header-hierro{
  background-image: url("assets/img/banners/hierro.jpg");
  color: #b5975a;
}
.header-mallas{
  background-image: url("assets/img/banners/mallas.jpg");
  color: #b5975a;
}
.header-mineria{
  background-image: url("assets/img/banners/mineria.jpg");
  color: #b5975a;
}
.header-pintura{
  background-image: url("assets/img/banners/pinturas.jpg");
  color: #b5975a;
}
.header-plastico{
  background-image: url("assets/img/banners/plasticos.jpg");
  color: #b5975a;
}
.header-seguridad{
  background-image: url("assets/img/banners/seguridad-industrial.jpg");
  color: #b5975a;
}
.header-soldadura{
  background-image: url("assets/img/banners/soldadura.jpg");
  color: #b5975a;
}
.header-techo{
  background-image: url("assets/img/banners/techos.jpg");
  color: #b5975a;
}
.letra{
  font-size: 4em;
  text-shadow: 2px 2px 5px black;
}
.breadcrumb a{
  color: #811f21;
}
.breadcrumb a:hover{
  color: #b5975a;
}
.ver-mas{
    text-align: center;
    background-color: white;
    border-color: #b5975a;
    border-width: 1px;
    border-style: solid;
    margin: auto;
    display: block;
    color: #811f21;
    font-size: 1.25em;
    margin-bottom: 5px;
    margin-top: 5px;
}
.ver-mas:hover{
  border-color: #b5975a;
  color: #b5975a;
  transform: scale(1.25);
}
.scroll-to-top{
  color: #b5975a;
  position: fixed;
  right: 7.5%;
  bottom: 7.5%;
  z-index: 1;
  animation: scroll alternate 1s ease infinite;
}
.scroll-to-top path{
  transform: scale(2.5);
}
@keyframes scroll {
  from{
    transform: translateY(0.5em);
    }
  to{
    transform: translateY(-0.5em);
  }
}
@media (max-width: 767px){
  .productos{
    grid-template-columns: 1fr;
  }
  .letra{
    font-size: 2.5em;
  }
}
@media (max-width: 480px){
  .logo{
    width: 75px;
  }
}
@media (max-width: 360px){
    #title h4{
    font-size: 1.1em;
  }
}